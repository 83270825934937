[data-widget-name='ws-site-text-search'] {
	.site-text-search-input {
		text-overflow: ellipsis;
	}

	&.sts-search-dropdown {
		z-index: 999;
	}

	&.sts-search-flyout {
		position: fixed;
		top: 0;
		right: 0;
		max-width: 100%;
		height: 100vh;
		z-index: 99999;
		transition: 0.25s;
		overflow-y: scroll;

		&.one-column {
			width: 446px;
			right: -446px;
		}

		&.two-columns {
			width: 824px;
			right: -824px;
		}
	}

	&.sts-search-modal {
		display: block;
		position: fixed;
		overflow: hidden;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99998;
		background-color: rgba(0, 0, 0, 0.6);
	}

	&.sts-search-flyout.slide-left {
		right: 0;

		&.mobile {
			width: 100%;
		}
	}

	&.sts-search-dropdown,
	&.sts-search-flyout {
		.inventory-tabs > .nav-tabs > li > a {
			--text-muted-color: var(--color-neutral-1000);

			margin-right: 0;
			margin-left: 0;
		}

		.tabs .nav-tabs > li > a {
			font-size: 0.75em;
			padding: 0.95em 1em;

			&:hover {
				padding: 0.95em 1em calc(0.95em - 1px) 1em;
			}
		}

		.inventory-tabs > .nav-tabs > li.disabled > a {
			color: var(--color-neutral-400);
		}

		.tabs .nav-tabs > li.active a,
		.tabs .nav-tabs > li.active > a:hover,
		.tabs .nav-tabs > li.active > a:focus {
			padding: 0.95em 1em calc(0.95em - 3px) 1em;
		}

		.inventory-tabs > .nav-tabs > li:not(.active):not(.disabled) > a {
			border-bottom: 1px solid var(--color-neutral-1000);
			padding: 0.95em 1em calc(0.95em - 3px) 1em;
		}
	}

	@media only screen and (max-width: 600px) {
		&.sts-search-dropdown,
		&.sts-search-flyout {
			.tabs .nav-tabs > li > a {
				font-size: 0.7em;

				&:hover {
					padding: 0.95em 1em calc(0.95em - 1px) 1em;
				}
			}

			.tabs .nav-tabs > li.active a,
			.tabs .nav-tabs > li.active > a:hover,
			.tabs .nav-tabs > li.active > a:focus {
				padding: 0.95em 1em calc(0.95em - 3px) 1em;
			}
		}
	}

	.site-text-search-wrapper {
		.input-container {
			position: relative;
			display: inline-block;
			width: 100%;
		}

		.input-container input {
			padding-top: 12px !important;
		}

		.floating-placeholder {
			position: absolute;
			font-weight: 200;
			top: 50%;
			font-size: 1em;
			transform: translateY(-50%);
			transition: all 0.2s ease;
			pointer-events: none;
		}

		.input-container.has-content .floating-placeholder,
		.input-container input:focus + .floating-placeholder {
			top: 20%;
			font-size: 0.6em;
			transform: translateY(-50%);
		}
	}

	.site-text-search-pagination {
		display: block;
		margin: 0 auto;
		max-width: 200px;
	}

	@media (max-width: 768px) {
		.site-text-search-wrapper {
			.floating-placeholder {
				font-size: 1.1em;
			}

			.input-container.has-content .floating-placeholder,
			.input-container input:focus + .floating-placeholder {
				font-size: 0.7em;
			}
		}
	}

	.site-search-vehicle-card-media-container {
		flex-basis: 40%;
		max-width: 100%;
	}

	.site-search-vehicle-card-details-container {
		flex-basis: 60%;
		max-width: 100%;
	}
}
